import React, { useEffect, useContext, useState, useRef } from 'react'
import { Container, Flex, Box, Badge, Text, Heading, css, IconButton, Input, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import ReactModal from 'react-modal'
import httpServiceToken from '../../../../../site/src/service/httpServiceToken'
import { AiOutlineClose, AiOutlineSearch, AiOutlineDelete, AiOutlineCloudDownload, AiOutlineCloudSync, AiOutlineCheck, AiOutlineArrowLeft } from "react-icons/ai";
import noSearchPng from '../../assets/no-search-die-cutting.png'
import { setKtTokenSifre, logOut, getKtLogin } from '../../../../../site/src/service/auth'
import { ModalContext } from '@solid-ui-components/Modal'
import loadsGif from '../../assets/loads.gif'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import indirmeCss from './_indirmelerim.css'
import removeGif from '../../assets/remove-popup.gif'
import Tabs from '@solid-ui-components/Tabs'


const styles = {
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 320,
    maxWidth: [`none`, null, null, 280],
    minHeight: 400,
    maxHeight: [`none`, null, null, 325],
    cursor: `pointer`,
    p: 4
  },
  card: {

  },
  artic: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },

  indirmeImageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `200px`
  },

  detayimageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `450px`,
    margin: '10px',
    maxWidth: 650
  },

  searchL: {
    flex: [`100%`, null, null, 1],
    minWidth: 400,
  },

  searchR: {
    flex: [`100%`, null, null, 1],
    minWidth: 200,
    maxWidth: [`none`, null, null, 350],
    textAlign: `right`,
    marginTop: '0.5em'
  },
  textInp: {
    flex: [`100%`, null, null, 1],
    height: 50,
    backgroundColor: "white"
  },
  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },

  wrapper2: {
    flex: [`100%`, null, null, 1],
    maxWidth: [`none`, null, null, 675],
    marginTop: "20px",
  },
  wrapper1: {
    flex: [`100%`, null, null, 1],
    maxWidth: [`none`, null, null, 350],
    marginTop: "20px",

  },

}


const TekliflerList = ({ ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const { setActiveModal } = useContext(ModalContext)

  const [btnID, setbtnID] = useState("");


  const formId = "frmTeklifler"

  const [Data, setData] = useState(Data);
  const [filterData, setFilterData] = useState(Data);
  const [seciliData, setSeciliData] = useState(Data);
  // const [silmePopup, setsilmePopup] = useState(false);


  useEffect(async () => {
    if (typeof window !== 'undefined') {
      const ktLoginMiii = await getKtLogin();
      if (ktLoginMiii == true) {
        teklifListDoldur();
      } else {
        logOut();
      }
    }
  }, []);

  const [ldg, setLdg] = useState(false);

  function teklifListDoldur() {
    // setLdg(false);
    httpServiceToken.post("Locked/TeklifListem", {}).then((res) => {
      if (res.DogruMu) {
        setData(res.Data);
        setFilterData(res.Data);
        setKtTokenSifre(res.Refresh);
        setLdg(true);
      } else {
        setLdg(true);
      }
    }).catch((err) => {
      setLdg(true);
    });

  }


  const onChanges = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value.replace(",", ".")
      }
    })
  }

  // 


  // const [detayModalShow, setdetayModalShow] = useState(false);


  const [aramaMi, setAramaMi] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [resimTipi, setresimTipi] = useState(1);


  const turkishCharacterRegex = keyword => keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');


  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })

  }, [success, submitting, setFormValues])



  const onChange = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }

    })
    setAramaMi(e.target.value.length == 0)
    setSearchText(e.target.value);

    let value = turkishCharacterRegex(e.target.value);
    let valueUpper = turkishCharacterRegex(e.target.value.toUpperCase());
    let valueLower = turkishCharacterRegex(e.target.value.toLowerCase());

    let result = [];
    result = Data.filter((data) => {
      data.Select = false;
      if (data.ModelAdi == "KT-0001") {
        let a = 0;
      }
      const arama = turkishCharacterRegex((data.TeklifNo + " " + data.MustAdi + " " + data.TeklifDurumText + " " + data.IsAdi).toUpperCase());
      const aramaList = arama.split(" ");
      aramaList.push(arama);

      const valueUPPERList = valueUpper.split(" ");
      valueUPPERList.map((valUPPER) => {
        aramaList.map((arm) => {
          if (data.Select == false)
            data.Select = arm.includes(valUPPER);
        });
      });

      const valueLOWERList = valueLower.split(" ");
      valueLOWERList.map((valLOWER) => {
        aramaList.map((arm) => {
          if (data.Select == false)
            data.Select = arm.includes(valLOWER);
        });
      });

      return data.Select;
    });
    setFilterData(result);
    if (e.target.value.length == 0) {
      setFilterData(Data);
    }
  }

  return (

    <Container>
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <div>
          <Box>
            <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, marginLeft: '1.5em', marginRight: '5.5em' }}>
              <Box sx={styles.searchL}>

                <Box variant='forms.field'>

                  <Input
                    {...props}
                    onChange={onChange}
                    placeholder={"Müşteri adı, iş adı, teklif no, teklif durumu girerek arayabilirsiniz "}
                    name={"txtSearch"}
                    id={"txtSearch"}
                    value={aramaMi == true ? "" : formValues?.[formId]?.["txtSearch"] || undefined}
                    sx={styles.textInp}
                  />
                  <Box sx={{ marginTop: '0.3em', p: 2 }}>
                    {aramaMi == true ?
                      <AiOutlineSearch onClick={e => {
                        setAramaMi(true);
                        setFilterData(Data);
                        setSearchText("");
                      }} size={21} color="grey" /> : <AiOutlineClose
                        onClick={e => {
                          setAramaMi(true);
                          setFilterData(Data);
                        }}
                        size={21} color="grey" />}
                  </Box>
                </Box>


              </Box>
              <Box sx={styles.searchR}>
                <div className="montaj-tab">
                  <Tabs sx={{ flex: 1, py: [2, 2], px: [1, 1] }} tabs={["Tasarım", "Çizim", "Açılım"]} onChange=
                    {e => {
                      setresimTipi(e + 1);
                    }}
                    variant='pill' /></div>
              </Box>
            </Flex>
          </Box>
          {filterData && (
            <Flex sx={{ flexWrap: `wrap`, justifyContent: `start`, m: -1 }}>
              {filterData.map(
                ({ Id,TeklifNo, CizimLinki, TeklifResimSol, TeklifResimSag, IsAdi, KullaniciAdi, MustAdi, TeklifTarihiText, TeklifDurumText }, index) => (
                  <Box key={`kti-${Id}`} sx={styles.wrapper}>
                    <ContentContainer
                      content={{
                        variant: "cards.interactive",
                      }}
                      variant='cards.interactive'
                      sx={{
                        overflow: `hidden`,
                        height: `full`,
                        backgroundColor: "#E0E0E0",
                        borderStyle: "none"
                      }} >
                      <Flex as='article' sx={styles.artic}>
                        <>
                          <div className='cls-model-adi' onClick={async e => {
                            // e.preventDefault();
                            // setSeciliData(filterData[index])
                            // setdetayModalShow(true);
                            window.location.assign("/ambalaj-kutu-maliyet-hesaplama?T=" + Id)

                          }} >
                            <Box sx={{ flex: [0, 1], m: 2, mb: [null, null, null, 0] }}>
                              <Box sx={styles.indirmeImageWrapper}>
                                {
                                  <div id="indirmeListSvg" style={{
                                    width: '100%',
                                    height: '100%',
                                    textAlign: "center"
                                  }}>
                                     {TeklifNo}
                                    <TransformWrapper  >
                                      <TransformComponent >
                                        <img src={resimTipi == 1 ? TeklifResimSag : resimTipi == 2 ? TeklifResimSol : CizimLinki}
                                          style={{
                                            width: 'auto',
                                            height: '100%',
                                          }} />
                                      </TransformComponent>
                                    </TransformWrapper>
                                  </div>
                                }
                              </Box>
                              <Divider space={1} />

                            </Box>
                          </div>
                          {/* <Flex
                            sx={{
                              alignItems: `center`,
                              justifyContent: `space-around`,

                            }}

                          >
                           
                          </Flex> */}
                          <Flex
                            sx={{
                              alignItems: `center`,
                              justifyContent: `center`,
                              flexDirection: 'column',
                            }}

                          >
                            <Box>
                              <Text
                                key={`mdls${Id}`}
                                color="black"
                                as="span"
                                style={{ fontSize: "small" }}
                              >
                                {MustAdi.length > 18 ? MustAdi.substring(0, 18) + ".." : MustAdi}
                              </Text>
                            </Box>
                            <Box>
                              <Text
                                key={`mdls${Id}`}
                                color="black"
                                as="span"
                                style={{ fontSize: "small" }}
                              >
                                {IsAdi.length > 18 ? IsAdi.substring(0, 18) + ".." : IsAdi}
                              </Text>
                            </Box>
                            <Box>
                              <Text
                                key={`mdls${Id}`}
                                color="gray"
                                as="span"
                                style={{ fontSize: "small" }}
                              >
                                {TeklifTarihiText}({KullaniciAdi.length > 8 ? KullaniciAdi.substring(0, 8) + ".." : KullaniciAdi})
                              </Text>
                            </Box>
                            <Box>
                              <Text
                                key={`mdls${Id}`}
                                color="black"
                                as="span"
                                style={{ fontSize: "small" }}
                              >
                                {TeklifDurumText.length > 18 ? TeklifDurumText.substring(0, 18) + ".." : TeklifDurumText}
                              </Text>
                            </Box>
                          </Flex></>
                      </Flex>
                    </ContentContainer>

                  </Box>
                )
              )}
            </Flex>
          )}
          {(
            filterData?.length == 0
            &&
            <div className='no-search'>
              <Divider space="2" />
              <img src={noSearchPng} alt="online kutu tasarımı" style={{ width: "200px", objectFit: "contain" }} />
              <div>Üzgünüz Aradığınız kriterler teklif listesinde bulunamadı</div>
              <div> <span onClick={e => {

                setFilterData(Data);
                setAramaMi(true);
                setSearchText("");

                e.preventDefault();
              }} className='search-tum'>Tüm Tekliflere</span> gözatarak aradığınız çizimi bulabilirsiniz</div>

              <Divider space="5" />
            </div>
          )}

        </div>}
      <div id="ifRa" style={{ display: "none", height: "0px" }}>
        <iframe id="ifRam" ></iframe>
      </div>
    </Container>
  )
}
export default WithDefaultContent(TekliflerList)
